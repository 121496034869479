*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.no-border-select .ant-select-selector {
    border: none !important;
    box-shadow: none !important; /* Also removes any shadow */
}
.no-border-select .ant-select-arrow{
  color: #000;
}

/* Optional: Remove focus outline */
.no-border-select .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
}